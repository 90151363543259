<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="transfer-archive"
        :canDelete="false"
        navigation="transfer-archives"
        title="Archived Transfer">
        <template v-slot="{ item }">
            <v-list-item dense>
                <v-list-item-avatar>
                    <v-img :src="companyLogoURL(item.buyerID)" class="mx-auto">
                        <template v-slot:placeholder>
                            <v-icon>mdi-account</v-icon>
                        </template>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        <BT-Entity
                            itemText="companyName"
                            :itemValue="item.buyerID"
                            navigation="public-companies"
                            single
                            useLocalCache />
                    </v-list-item-title>
                </v-list-item-content>
                <!-- <v-list-item-action>
                    <BT-Menu
                        hideHeader
                        icon="mdi-dots-horizontal">
                        <template v-slot>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Created on {{ item.createdOn | toLongDateAndTime }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-Menu>
                </v-list-item-action> -->
            </v-list-item>

            <BT-Field-Entity
                :itemValue="item.departureLocationID"
                label="Departure Location"
                navigation="public-locations"
                single
                textFilter="toFamiliarLocationLine" />

            <BT-Field-Entity
                :itemValue="item.destinationLocationID"
                label="Destination Location"
                navigation="public-locations"
                single
                textFilter="toFamiliarLocationLine" />

            <BT-Field-Date
                v-model="item.dueDate"
                label="Due On" />

            <BT-Field-Date
                v-model="item.performedOn"
                label="Performed On" />

            <BT-Snack v-model="msg" />
            
            <v-expansion-panels v-model="panelV">
                <BT-Blade-Expansion-Items
                    :canSelect="false"
                    hideActions
                    :items="item.transferOrderItems"
                    showList
                    title="Transfered Stock Items">
                    <template v-slot:listItem="{ item }">
                        <v-list-item-action>
                            {{ item.quantity | toDisplayNumber }}
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                <BT-Entity
                                    navigation="public-products"
                                    :itemValue="item.productID"
                                    itemText="productName"
                                    single
                                    useLocalCache />
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ item.batch != null ? item.batch.batchcode : 'Not Found' }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </BT-Blade-Expansion-Items>
                <BT-Blade-Expansion-Items
                    :canSelect="false"
                    hideActions
                    :items="item.packagedItems"
                    showList
                    title="Packaged Items">
                    <template v-slot:listItem="{ item }">
                        <v-list-item-content>
                            {{ item }}
                        </v-list-item-content>
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels> 
            
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Stock-Consignment-Blade',
    components: {
        // BTMenu: () => import('~components/BT-Menu.vue')
    },
    data: function() {
        return {
            msg: null,
            panelV: 0,
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        // pullArchive(transfer) {
        //     if (this.isLengthyArray(transfer.transferOrderItems)) {
        //         transfer.transferOrderItems.sort(firstBy(z => z.))
        //     }
        // }
    }
}
</script>